<template>
    <div v-if="initializing"> </div>
    <div v-if="!initializing"> 
        <fieldset :disabled="props.readOnly">
            <o-data-grid :data-object="dsGRPurchaseOrderItems" hideGridMenu hideNewRecords style="min-height: 350px;">
                <template #cardheader >
                    <div>
                        <h4 class="ms-2 mb-2 p-2 d-inline-block"> Items </h4>
                    </div>
                    <span class="m-2"> 
                        <button v-if="dsGRPurchaseOrder.current.Status != 'Received'" class="btn btn-primary btn-sm set-po-as-recieved" @click="setPOAsRecieved" >{{ $t("Set PO as recieved") }} <i class="bi bi-check-lg"></i> </button>
                    </span>
                </template>          

                <o-column field="ProductNo" width="150" > </o-column>
                <o-column field="Product" width="250" ></o-column>
                <o-column field="Unit"  width="100"></o-column>
                <o-column field="QtyOrdered"  width="100" class="text-end"></o-column>
                <o-column field="QtyReceived" width="120" class="text-end"> 
                    <template #default="{row}">
                        <div class="w-100 h-100" style="cursor:pointer;" @click="show">{{ row.QtyReceived == null ? 0 : row.QtyReceived }}</div>              
                    </template>
                </o-column>
            </o-data-grid> 
        </fieldset>
        <OModal ref="myModalRef">
            <div class="modal-dialog" v-if="!vRequireObject">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"> {{ $t("Recieved Items") }} </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <fieldset :disabled="props.readOnly">
                        <div class="modal-body">
                            <div>
                                <label class="form-label"> {{ $t("Quantity") }} </label>
                                <input class="form-control" type="number" style="width: 200px;" v-model="vQuantity" min="1" /> 
                            </div>
                            <div>
                                <label class="form-label"> {{ $t("Traceability No") }} </label>
                                <input class="form-control" type="text" style="" v-model="vTraceabilityNo" /> 
                            </div>
                            <div>
                                <span type="date" style="font-weight: bold;" > {{ $t("Transactions") }} </span>
                                <p v-for="item in dsGRMaterialMovements.data" style="width: 350px;">
                                    <span type="date" style="" format="Short Date"> {{ utils.formatDate(item.Created) }} : </span>
                                    <span v-if="item.TraceabilityNo" type="text" > {{item.TraceabilityNo}} - </span>
                                    <span type="text" style="font-weight: bold;"> {{item.Quantity}} </span>
                                </p>
                            </div>
                        </div>
                    </fieldset>
                        <div class="modal-footer">
                            <div v-if="loading" >
                                <span class="spinner-border spinner-border-sm"> </span>
                            </div>
                            <div v-if="!loading" >
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="margin-right: 8px;"> {{ $t("Close") }} </button>
                                <button type="button" class="btn btn-primary" @click="checkinItems" :disabled="props.readOnly"> {{ $t("Checkin Items") }} </button>
                            </div>
                        </div>
                </div>
            </div>
            <div  class="modal-dialog" v-if="vRequireObject" style="min-inline-size: fit-content;"> 
                <div class="modal-content" >
                    <div class="modal-header">
                        <h5 class="modal-title"> {{ $t("Create Objects") }} </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <fieldset :disabled="props.readOnly">
                        <div class="modal-body">
                            <div>
                                <label class="form-label"> {{ $t("Traceability No") }} </label>
                                <input class="form-control" type="text" style="margin-bottom:8px;" v-model="vTraceabilityNo" /> 
                            </div>
                            <div v-if="!props.readOnly"> 
                                <o-data-grid :data-object="dsGRPOItemObjects" hideGridMenu :createNewOverrideFn="createNewObject">       

                                    <o-column field="SerialNo" width="150" editable > </o-column>
                                    <o-column field="Description" width="200" editable></o-column>
                                    <o-column field="ObjectType"  width="100"></o-column>
                                    <o-column field="ObjectType_ID"  width="100"></o-column>

                                </o-data-grid>
                            </div> 
                            <div>
                                <span type="date" style="font-weight: bold;" > {{ $t("Created Objects") }} </span>
                                <p v-for="item in dsGRObjects.data" style="">
                                    <span type="date" style="" format="Short Date"> {{ utils.formatDate(item.Created) }} : </span>
                                    <span v-if="item.SerialNo" type="text" > {{item.SerialNo}} - </span>
                                    <span type="text" style="font-weight: bold;"> {{item.Name}} </span>
                                </p>
                            </div>
                        </div>
                    </fieldset>
                    <div class="modal-footer">
                        <div v-if="loading" >
                            <span class="spinner-border spinner-border-sm"> </span>
                        </div>
                        <div v-if="!loading" >
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" style="margin-right: 8px;"> {{ $t("Close") }} </button>
                            <button type="button" class="btn btn-primary" @click="createItemObjects" :disabled="props.readOnly"> {{ $t("Create Objects") }} </button>
                        </div>
                    </div>
                </div>
            </div>
            
        </OModal>
    </div>
    <!-- <Modals ref="myModalRef" /> -->
</template>

<script setup>
    import { getOrCreateDataObject, getOrCreateProcedure } from 'o365.vue.ts';
    import { ref, watch, onMounted } from 'vue'; 
    import alert from 'o365.controls.alert.ts';
    import utils from 'o365.modules.utils.js'; 

    const myModalRef = ref(null);


    function setPOAsRecieved(){
        dsGRPurchaseOrder.current.Status = 'Received';
        dsGRPurchaseOrder.save().then(() => dsGRPurchaseOrder.refreshRow());
    }

// Modal

    // var dsGRPOItemObjects = getDataObjectById("dsGRPOItemObjects");
    // var dsGRPurchaseOrders = getDataObjectById("dsGRPurchaseOrders");
    // var dsGRMaterialMovements = getDataObjectById("dsGRMaterialMovements");
    // var dsGRObjects = getDataObjectById("dsGRObjects");
    
    const procGoodsReceipt = getOrCreateProcedure({ id:"procGoodsReceipt", procedureName:"astp_Stock_GoodsReceipt" });
    const procGoodsReceiptWithObjects = getOrCreateProcedure({ id:"procGoodsReceiptWithObjects", procedureName:"astp_Stock_GoodsReceiptWithObjects" });

    const vQuantity = ref(null);
    const vTraceabilityNo = ref(null);
    const vObjectID = ref(null);
    const loading = ref(null);
    const vRequireObject = ref(null);
    const initializing = ref(true) 

    const props = defineProps({
        itemId: Number,
        stepId: Number,
        poid: Number,
        readOnly:Boolean
    });

    defineExpose({
        refresh
    });

    function refresh(i){
        console.log(i);
        dsGRPurchaseOrder.recordSource.whereClause = "ID = " + i ?? '1';
        dsGRPurchaseOrder.load();
    }


    const dsGRPurchaseOrder = getOrCreateDataObject(
    {  
        "id": "dsGRPurchaseOrder",
        "viewName": "aviw_Stock_PurchaseOrders",
        "distinctRows": false,
        "uniqueTable": "atbl_Stock_PurchaseOrders",
        "allowUpdate": props.readOnly ? false : true,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Status",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "OrgUnit",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Supplier_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "OrgUnit_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Workflow_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            }
            
        ],
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": 1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    if(!props.poid && props.itemId) {
    //debugger;
        dsGRPurchaseOrder.recordSource.whereClause = "Workflow_ID = " + props.itemId;
    } else {
    //debugger;
        //dsGRPurchaseOrder.recordSource.whereClause = "ID = " + props.poid;
    }

    const dsGRPurchaseOrderItems = getOrCreateDataObject({
        "id": "dsGRPurchaseOrderItems",
        "viewName": "aviw_Stock_PurchaseOrdersItems",
        "distinctRows": false,
        "uniqueTable": "atbl_Stock_PurchaseOrdersItems",
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "PurchaseOrder_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Product_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Comments",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Product",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "QtyOrdered",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Unit",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Unit_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "AllowMetaChanges",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "SupplierArticleNo",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ProductNo",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "PricePerUnitWithoutDiscount",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "TotalPrice",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Discount",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "PricePerUnit",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Supplier_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ExchangeRate",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ActualDeliveryDate",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "EstimatedDeliveryDate",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "DefaultObjectType_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "RequireObject",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "QtyReceived",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            
        ],
        "masterDataObject_ID": "dsGRPurchaseOrder",
        "masterDetailDefinition": [
            {
                "detailField": "PurchaseOrder_ID",
                "operator": "equals",
                "masterField": "ID"
            }
        ],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause": "",
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });
    
    const dsGRPOItemObjects = getOrCreateDataObject(
    {
        "id": "dsGRPOItemObjects",
        "viewName": "aviw_Stock_PurchaseOrdersItemsObjects",
        "distinctRows": false,
        "uniqueTable": "atbl_Stock_PurchaseOrdersItemsObjects",
        "allowUpdate": true,
        "allowInsert": true,
        "allowDelete": true,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ObjectType",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "OrgUnit",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Description",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ObjectType_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "OrgUnit_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ObjectName",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "PurchaseOrderItem_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "UpdatedBy_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "SerialNo",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            }
        ],
        "masterDataObject_ID": "dsGRPurchaseOrderItems",
        "masterDetailDefinition": [
            {
                "detailField": "PurchaseOrderItem_ID",
                "operator": "equals",
                "masterField": "ID"
            }
        ],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });

    const dsGRObjectTypeLkp = getOrCreateDataObject(
    {
        "id": "dsGRObjectTypeLkp",
        "viewName": "aviw_Assets_ObjectTypesLookup",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "Name",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            }
        ],
        "masterDataObject_ID": null,
        "masterDetailDefinition": [],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });
    

    const dsGRObjects = getOrCreateDataObject(
    {
        "id": "dsGRObjects",
        "viewName": "atbv_Assets_Objects",
        "distinctRows": false,
        "uniqueTable": null,
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Description",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "OrgUnit_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "PurchaseOrderItem_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "SerialNo",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Product_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Name",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Created",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            }
        ],
        "masterDataObject_ID": "dsGRPurchaseOrderItems",
        "masterDetailDefinition": [
            {
                "detailField": "PurchaseOrderItem_ID",
                "operator": "equals",
                "masterField": "ID"
            }
        ],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });



    const dsGRMaterialMovements = getOrCreateDataObject(
    {
        "id": "dsGRMaterialMovements",
        "viewName": "atbv_Stock_MaterialMovements",
        "distinctRows": false,
        "uniqueTable": "atbl_Stock_MaterialMovements",
        "allowUpdate": false,
        "allowInsert": false,
        "allowDelete": false,
        "appendData": false,
        "selectFirstRowOnLoad": true,
        "fields": [
            {
                "name": "ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Product_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Object_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "ProductName",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "MovementType",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "PurchaseOrderItem_ID",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "TraceabilityNo",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Quantity",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
            {
                "name": "Created",
                "sortOrder": null,
                "sortDirection": null,
                "groupByOrder": null,
                "groupByAggregate": null,
                "type": null,
                "aggregate": null
            },
        ],
        "masterDataObject_ID": "dsGRPurchaseOrderItems",
        "masterDetailDefinition": [
            {
                "detailField": "PurchaseOrderItem_ID",
                "operator": "equals",
                "masterField": "ID"
            }
        ],
        "clientSideHandler": false,
        "maxRecords": -1,
        "dynamicLoading": false,
        "whereClause": null,
        "filterString": null,
        "disableAutoLoad": false,
        "optimisticLocking": false
    });
    onMounted( () => {
        dsGRPurchaseOrder.load().then(()=> initializing.value = false);
    });

    dsGRPurchaseOrderItems.fields.loadAlways = ['RequireObject', 'DefaultObjectType_ID']; 
    dsGRPurchaseOrder.fields.loadAlways = ['OrgUnit_ID', 'OrgUnit']; 
    dsGRPOItemObjects.fields.loadAlways = ['OrgUnit_ID']; 


    function show() {
        
        setTimeout(() => { 
        myModalRef.value.show();
        dsGRMaterialMovements.load();
        
        vQuantity.value = dsGRPurchaseOrderItems.current.QtyOrdered
        loading.value = false;
        vTraceabilityNo.value = null;
        vRequireObject.value = dsGRPurchaseOrderItems.current.RequireObject;
        }, 100);
    };

    function checkinItems(){
        if(loading.value) { return; }
        loading.value = true;
        console.log(vQuantity.value);
        console.log(dsGRPurchaseOrderItems.current.ID);
        console.log(dsGRPurchaseOrderItems.current.RequireObject);
        console.log(vTraceabilityNo.value);

    if(vObjectID.value == null && dsGRPurchaseOrderItems.current.RequireObject)
    {

        loading.value = false;
    } else {
            procGoodsReceipt.execute({
                PurchaseOrderItem_ID : dsGRPurchaseOrderItems.current.ID,
                QuantityReceived : vQuantity.value,
                Object_ID : null,
                TraceabilityNo : vTraceabilityNo.value
            })
        .then((pData)=>{
            alert( $t( "Items checked in"), 'success', {
                    autohide : true ,
                    delay    : 2000 ,
                });
            if(dsGRPurchaseOrder.current.Status != 'Partially received') { 
                dsGRPurchaseOrder.current.Status = 'Partially received';
                dsGRPurchaseOrder.save().then(() => dsGRPurchaseOrder.refreshRow());
            }
            dsGRPurchaseOrderItems.load();
            dsGRPOItemObjects.load();
            loading.value = false;
            myModalRef.value.hide();
        }).catch((pErr)=>{
            loading.value = false;
            if(pErr){         

            }
        });
    }
    } 

    dsGRPOItemObjects.on("BeforeSave", (p, i) => { 
    
    });

    function createNewObject() { 
        console.log(dsGRPurchaseOrderItems.current.DefaultObjectType_ID);
        dsGRPOItemObjects.createNew({
            OrgUnit_ID: dsGRPurchaseOrder.current.OrgUnit_ID,
            ObjectType_ID: dsGRPurchaseOrderItems.current.DefaultObjectType_ID
        });
        setTimeout(() => {
            dsGRPOItemObjects.load();
        }, 99);
    }

    function createItemObjects() {
        loading.value = true;
        procGoodsReceiptWithObjects.execute({
                PurchaseOrderItem_ID : dsGRPurchaseOrderItems.current.ID,
                TraceabilityNo : vTraceabilityNo.value
            })
        .then((pData)=>{
            alert( $t( "Items checked in, and objects created" ), "success", {
                autohide : true ,
                delay    : 2000 ,
            });
            if(dsGRPurchaseOrder.current.Status != 'Partially received') { 
                dsGRPurchaseOrder.current.Status = 'Partially received';
                dsGRPurchaseOrder.save().then(() => dsGRPurchaseOrder.refreshRow());
            }
            dsGRPurchaseOrderItems.load();
            loading.value = false;
            myModalRef.value.hide();
        }).catch((pErr)=>{
            loading.value = false;
            if(pErr){                
                //alert('Something went wrong: ' + pErr, ToastType.Info);
            }
        });
    }

    // @blur="item.qty < 1 ? item.qty = 1 : item.qty = item.qty"
</script>